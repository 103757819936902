var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "h-full min-h-screen overflow-y-auto relative" },
    [
      _c(
        "div",
        {
          staticClass: "container mx-auto relative px-6 md:px-12 py-4 md:py-12",
        },
        [
          _c(
            "header",
            {
              staticClass:
                "w-full flex flex-row justify-between items-center py-2",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "w-1/2 flex flex-col justify-start items-start md:flex-row md:items-center md:mr-2",
                },
                [
                  _c(
                    "div",
                    { staticClass: "flex flex-row justify-start items-center" },
                    [
                      _vm.customDomain
                        ? _c("custom-brand-img", {
                            attrs: {
                              type: _vm.customImgTypes.BG_LIGHT,
                              "img-class": "custom-brand",
                            },
                          })
                        : _c("sun-media-logo-dark", { staticClass: "w-48" }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "relative w-1/2 flex justify-end flex-1 p-2" },
                [_c("asterix-user-menu")],
                1
              ),
            ]
          ),
          _c(
            "section",
            {
              staticClass:
                "relative flex flex-col justify-start items-start w-full bg-white mb-2 p-2 sm:p-3 rounded rounded-lg shadow-md text-left sm:mt-1 md:mt-2 lg:mt-4",
            },
            [
              _c(
                "div",
                { staticClass: "flex w-full justify-center py-2" },
                [
                  _c("access-denied", {
                    staticClass: "w-32 h-32 md:w-48 md:h-48 lg:w-64 lg:h-64",
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "w-full py-2" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "text-sm md:text-lg lg:text-xl xl:text-2xl text-gray-700 cursor-default text-center",
                  },
                  [_vm._v(" " + _vm._s(_vm.pageNotFoundText) + " ")]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "text-sm sm:text-base md:text-lg text-orange-500 font-bold my-4 lg:my-8 text-center",
                  },
                  [
                    _c(
                      "router-link",
                      { staticClass: "w-auto", attrs: { to: "/" } },
                      [
                        _c(
                          "sun-button",
                          {
                            staticClass:
                              "w-auto px-6 mr-2 mb-1 text-sm whitespace-no-wrap rounded-full text-white bg-orange-600",
                            attrs: { variant: "pill", color: "orange" },
                          },
                          [_vm._v(" " + _vm._s(_vm.goHomeText) + " ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _c("asterix-footer"),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }